import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.mask = document.createElement("input");
    this.mask.type = "text";
    this.mask.autocomplete = "off";
    this.mask.spellcheck = false;
    this.mask.className = this.element.className;
    this.mask.id = this.element.id + "_mask";
    this.mask.value = `${this.element.value}%`;

    // Add blur event listener to update the value
    this.mask.addEventListener("blur", () => this.update());

    // Add keypress event listener to handle Enter key press
    this.mask.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        // Check for Enter key
        this.update(); // Update the value immediately
        // Optionally, you can trigger form submission here if needed
        // But usually, allowing the event to proceed will submit the form
      }
    });

    this.element.parentNode.insertBefore(this.mask, this.element.nextSibling);
    this.element.style.display = "none";
  }

  update() {
    let value = parseInt(this.mask.value.match(/^\D*(\d+)/)[1]);
    if (value >= 0 && value <= 100) {
      this.element.value = value;
      this.element.dispatchEvent(new Event("change"));
    } else {
      value = this.element.value;
    }

    this.mask.value = `${value}%`;
  }
}
