import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

export default class extends Controller {
  connect() {
    this.element.style.display = "none";

    this.mask = document.createElement("input");
    this.mask.type = "text";
    this.mask.className = this.element.className;
    this.mask.id = this.element.id + "_mask";
    this.mask.spellcheck = false;
    this.mask.autocomplete = "off";

    // Add blur event listener to update the value
    this.mask.addEventListener("blur", () => this.update());

    // Add keypress event listener to handle Enter key press
    this.mask.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        // Check for Enter key
        this.update(); // Update the value immediately
        // Optionally, you can trigger form submission here if needed
        // But usually, allowing the event to proceed will submit the form
      }
    });

    this.unit = this.element.dataset.unit;
    this.delimiter = this.element.dataset.delimiter;
    this.precision = parseInt(this.element.dataset.precision);
    this.separator = this.element.dataset.separator;

    this.element.parentNode.insertBefore(this.mask, this.element.nextSibling);

    if (this.element.value) {
      this.mask.value = this.formatCurrency(this.element.value / 100);
    }
  }

  update() {
    const val = currency(this.mask.value, { separator: this.separator }).value;

    if (val === 0) {
      this.element.value = "";
      this.mask.value = "";
    } else {
      this.element.value = Math.round(val * 100);
      this.mask.value = this.formatCurrency(val);
    }
    this.element.dispatchEvent(new Event("change"));
  }

  formatCurrency(val) {
    return currency(val, {
      symbol: this.unit,
      precision: this.precision,
      separator: this.separator,
      formatWithSymbol: true,
    }).format();
  }
}
