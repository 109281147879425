import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon", "menu"];

  toggle() {
    this.menuTarget.classList.toggle("hidden");
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("rotate-90");
      this.iconTarget.classList.toggle("text-gray-500");
    }
  }
}
