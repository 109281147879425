import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (localStorage.getItem("bannerDismissed") === "true") {
      this.element.style.display = "none";
    }
  }

  dismiss() {
    localStorage.setItem("bannerDismissed", "true");
    this.element.style.display = "none";
  }
}
