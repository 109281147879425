import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.style.cursor = "pointer";

    // Set cursor style to default for cells with the rowlink-skip class
    this.element.querySelectorAll(".rowlink-skip").forEach((cell) => {
      cell.style.cursor = "default";
    });
  }

  click(event) {
    if (!event.target.classList.contains("rowlink-skip")) {
      const link = this.element.querySelector("a");
      if (link) {
        link.click();
      }
    }
  }
}
