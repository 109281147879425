import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "closeButton", "menu", "wrapper"];

  toggle() {
    this.backdropTarget.classList.toggle("opacity-0");
    this.closeButtonTarget.classList.toggle("opacity-0");
    this.menuTarget.classList.toggle("-translate-x-full");
    this.wrapperTarget.classList.toggle("pointer-events-none");
  }
}
